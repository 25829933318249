<template>
  <div class="whiteBack">
    <div>
      <h3>Upload Your Certifications</h3>
      <!-- <label>Upload Your Certifications</label> -->
      <!-- <div class="caption strong pt-3">Where to get Certified:</div>
      <div class="caption">We recommend obtaining your <a href="https://www.servsafe.com/ServSafe-Food-Handler" target="_blank">food handler</a> certification from ServSafe. Please give make sure you certificates are updated before applying for any shifts that require them.</div> -->
    </div>
    <div class="mt-2">
      <label for="title">Choose Type:</label>
      <v-select
        label="title" 
        :options="certs"
        v-model="type"
        :clearable="true"
        >
      </v-select>
    </div>
    <div class="mt-3" >
      <label for="fileTitle" v-if="type">Upload File:</label>
      <div class="mt-2" v-if="type">
      <input type="file" ref="fileInputTip" id="certUpload" accept="image/*,application/pdf,.doc" @change="previewImage" hidden>
      <button @click="chooseFile()" class="btn btn__primary mb-2">Upload File</button>
      </div>

      <!-- <input type="file" ref="fileInputTip" accept="image/*,application/pdf,.doc" @change="previewImage"> -->
      <progress :value="uploadValue" max="100" v-if="showBar"></progress>
     <!--  <div class="mb-3">
        <button v-if="imageData != null && type" class="btn btn__primary mt-3" @click="onUploadFile">
          Upload
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
      </div> -->
    </div>
    <div v-if="userProfile && userProfile.certs && userProfile.certs.length >= 1" class="pt-3">
                <vue-good-table
                  :columns="columns"
                  :rows="userProfile.certs"
                  >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'url'">
                      <a :href="props.row.url" target="_blank">View <i class="fas fa-external-link"></i></a>
                    </span>
                    <span v-else-if="props.column.field == 'type'">
                      {{props.row.type}}
                    </span>
                    <span v-else-if="props.column.field == 'extras'">

                        <i class="fas fa-trash" @click="deleteUploadedFile(props.row, props.index)"></i>
        
                    </span>
                    <!-- <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span> -->
                  </template>
                </vue-good-table>
              </div>

	</div>
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  props: ['userProfile', 'certs'],
  data: () => ({ 
    type: '',
    // types: ['Food Handler', 'Alcohol Awareness', 'Guard Card', 'Forklift', 'Scissor Lift Operator', 'Other'],
    imageData: null,
    uploadValue: 0,
    showBar:false,
    performingRequest: false,
    columns: [
      {
        label: 'Type',
        field: 'type',
        sortable: false
      },
      {
        label: 'Url',
        field: 'url',
        sortable: false
      },
      {
        label: 'Delete',
        field: 'extras',
        sortable: false
      },
    ]
  }),
  methods: {
    chooseFile() {
      document.getElementById("certUpload").click()
    },
    previewImage(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadFile()
    },
    onUploadFile() {
      console.log('onUpload')
      this.showBar = true
      let type = this.type
      let userProfile = this.userProfile
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(userProfile.id)
          docRef.update({
            certs: fb.firestore.FieldValue.arrayUnion({
              type: type,
              url: downloadURL
            })
          });
          return userProfile.certs.push({
            type: type,
            url: downloadURL
          })
        })
        this.showBar = false
      })
      this.imageData = null
      this.type = ''
      this.$refs.fileInputTip.value=null
    },
    deleteUploadedFile(u, index) {
      console.log(u)
      let userProfile = this.userProfile
      userProfile.certs.splice(index, 1)
      this.$store.dispatch('updateUser', userProfile)
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    // previewImage(event) {
    //   this.uploadValue=0;
    //   this.imageData=event.target.files[0]
    // },
   
  },
}
</script>
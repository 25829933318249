<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      <ProfileCertifications :userProfile="userProfile" :certs="certs" />
    </div>
    <div class="dashboard__container--body--col">
      <div v-for="(item, index) in certs" class="mb-3">
        <h5>{{item.title}}</h5>
        <div class="caption mb-1">{{item.description}}</div>
        <div class="caption" v-if="item.url">Link: <a :href="item.url" target="_blank">{{item.url}}</a>  </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileCertifications from '@/components/Profile/ProfileCertifications.vue'

export default {
  name: 'accountUploads',
  created () {
    this.$store.dispatch("getCerts")
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'certs']),
  },
   components: {
    Loader,
    ProfileCertifications,
  },
  beforeDestroy() {
    this.$store.dispatch("clearCerts")
  }
}
</script>